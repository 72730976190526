import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import LinearProgess from "../../../../components/LinearProgess";
import TextMaskCustomPhone from "../../../../components/TextMaskCustomMobile";
import getOnlyNumbers from "../../../../services/getOnlyNumbers";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../store/ducks/subTeamSubUnitsContact";
import { Actions as SubTeamUnitsActions } from "../../../../store/ducks/subTeamUnits";
import { Actions as SubTeamSubUnitsctions } from "../../../../store/ducks/subTeamSubUnits";
import { Grid, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import MultiEmailInput from "../../../../components/MultiEmailInput";
import { constants } from "../../../../config";

export default function FormDialog({ classes, subTeamUnitsQuery }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [idSubTeamUnits, setIdSubTeamUnits] = useState("");
  const [idSubTeamSubUnits, setIdSubTeamSubUnits] = useState("");

  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const subTeamSubUnits = useSelector(state => state.subTeamSubUnits);
  const subTeamSubUnitsContact = useSelector(
    state => state.subTeamSubUnitsContact
  );
  const dispatch = useDispatch();
  const [dashboardParams, setDashboardParams] = useState(null);

  useEffect(() => {
    dispatch(SubTeamUnitsActions.getSubTeamUnitsRequest(subTeamUnitsQuery));
  }, []);

  useEffect(() => {
    const storedParameters = JSON.parse(localStorage.getItem("auth"))
      .currentTeam.parameters;

    const data = { parameters: storedParameters };

    if (
      storedParameters.dashboardParams &&
      storedParameters.dashboardParams.length
    ) {
      Object.assign(data, {
        dashboardParams: JSON.parse(storedParameters.dashboardParams)
      });
    }

    setDashboardParams(data.dashboardParams);
  }, []);

  return (
    <div>
      <Dialog
        open={
          subTeamSubUnitsContact.setDialogCreateVisibleSubTeamSubUnitsContact
        }
        onClose={() =>
          dispatch(Actions.setDialogCreateVisibleSubTeamSubUnitsContact(false))
        }
        aria-labelledby="form-dialog-title"
      >
        {subTeamSubUnitsContact.loading && <LinearProgess />}
        <DialogTitle id="form-dialog-title">Criar um morador*</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe abaixo os dados do morador
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                paddingLeft: 0
              }}
              xs={12}
              md={12}
            >
              <TextField
                disabled={
                  subTeamSubUnitsContact.loading ||
                  subTeamUnits.loading ||
                  !subTeamUnits.data.length
                }
                select
                label={
                  dashboardParams &&
                  Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                    ? dashboardParams.subTeamUnitsLabel
                    : constants.deliveryTableHeaders.subTeamUnitsCondominium
                }
                className={classes.selectType}
                value={idSubTeamUnits}
                onChange={e => {
                  setIdSubTeamUnits(e.target.value);
                  dispatch(
                    SubTeamSubUnitsctions.getSubTeamSubUnitsRequest(
                      `idSubTeamUnits=${e.target.value}&perPage=1000`
                    )
                  );
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant="outlined"
              >
                {subTeamUnits.data.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              style={{
                paddingLeft: 0
              }}
              xs={12}
              md={12}
            >
              <TextField
                disabled={
                  subTeamSubUnits.loading ||
                  !subTeamSubUnits.data.length ||
                  subTeamSubUnitsContact.loading
                }
                select
                label={
                  dashboardParams &&
                  Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
                    ? dashboardParams.subTeamSubUnitsLabel
                    : constants.deliveryTableHeaders.subTeamSubUnitsCondominium
                }
                className={classes.selectType}
                value={idSubTeamSubUnits}
                onChange={e => setIdSubTeamSubUnits(e.target.value)}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant="outlined"
              >
                {subTeamSubUnits.data.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              style={{
                paddingLeft: 0
              }}
              xs={12}
              md={12}
            >
              <TextField
                className={classes.textField}
                variant="outlined"
                disabled={subTeamSubUnitsContact.loading}
                label="Nome*"
                fullWidth
                onChange={e => setName(e.target.value)}
                value={name}
                autoComplete="off"
              />
            </Grid>

            <Grid
              item
              style={{
                paddingLeft: 0
              }}
              xs={12}
              md={12}
            >
              <MultiEmailInput
                extractEmails={e => setEmail(e)}
                defaultValue={email}
                disabled={subTeamSubUnitsContact.loading}
              />
            </Grid>

            <Grid
              item
              style={{
                paddingLeft: 0
              }}
              xs={12}
              md={12}
            >
              <TextField
                className={classes.textField}
                variant="outlined"
                disabled={subTeamSubUnitsContact.loading}
                label="Número de contato"
                placeholder="(##) #####-####"
                fullWidth
                onChange={e => setPhone(getOnlyNumbers(e.target.value))}
                value={phone}
                autoComplete="off"
                InputProps={{
                  inputComponent: TextMaskCustomPhone
                }}
                error={phone && phone.length < 11}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              dispatch(
                Actions.setDialogCreateVisibleSubTeamSubUnitsContact(false)
              )
            }
            color="danger"
            disabled={subTeamSubUnitsContact.loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (!name || !email) return;

              dispatch(
                Actions.createSubTeamSubUnitsContactRequest(
                  [
                    {
                      name,
                      email,
                      phone,
                      idSubTeamUnits,
                      idSubTeamSubUnits
                    }
                  ],
                  true
                )
              );
            }}
            color="primary"
            disabled={
              subTeamSubUnitsContact.loading ||
              !name ||
              !email ||
              !idSubTeamUnits ||
              !idSubTeamSubUnits ||
              (phone && phone.length < 11)
            }
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
